import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { errorSnackStyle } from "./errorSnackStyle";

const errorSnackbarComponent = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} sx={errorSnackStyle.root}>
      <Card sx={errorSnackStyle.card}>
        <CardActions sx={errorSnackStyle.actionRoot}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="body2" sx={errorSnackStyle.typography}>
              {props.message}
            </Typography>
            <div sx={errorSnackStyle.icons}>
              <IconButton
                size="small"
                sx={errorSnackStyle.expand}
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

errorSnackbarComponent.displayName = "errorSnackbarComponent";

export default errorSnackbarComponent;
