//Create a Http Client using Axios. Further modifications in this layer can be done later like Authorization.
import axios from "axios";
import qs from "qs";
import { BASE_URL } from "../config";
import Cookies from "js-cookie";
import { history } from "../history";
import { persistor } from "../store/store";

const LOGIN_BASE_URL = `${BASE_URL}authenticator/Auth`;
let interceptorId;

const interceptors = (token, _contentType) => {
  // If there's an existing interceptor, eject it before adding a new one
  if (interceptorId !== undefined) {
    axios.interceptors.request.eject(interceptorId);
  }

  interceptorId = axios.interceptors.request.use(
    function (config) {
      config.headers = {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        "Content-Type": _contentType ? _contentType : "application/json",
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

// Function to clear the interceptor
const clearInterceptor = () => {
  if (interceptorId !== undefined) {
    axios.interceptors.request.eject(interceptorId);
    interceptorId = undefined; // Reset the interceptor ID
  }
};

export const clearCache = () => {
  clearInterceptor();
  Cookies.remove("tdmAccessToken");
  Cookies.remove("tdmRefreshToken");
  Cookies.remove("activeStep");
  persistor.purge()
  history.push("/");
  window.location.reload();
  return "Session removed";
};

const createRefreshTokenFormData = () => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("scope", "openid offline_access tdmidentityscope");
  params.append("client_id", "reactSPA");
  params.append("client_secret", "tdm_98jD40f15");
  params.append("refresh_Token", Cookies.get("tdmRefreshToken"));
  return params;
};

Cookies.set("isAlreadyFetchingAccessToken", "false", {
  secure: true,
  sameSite: 'Strict',
});
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (Cookies.get("isAlreadyFetchingAccessToken") === "false") {
        Cookies.set("isAlreadyFetchingAccessToken", "true", {
          secure: true,
          sameSite: 'Strict',
        });
        clearInterceptor();
        interceptors("", "application/x-www-form-urlencoded");
        const resp = await axios.post(
          LOGIN_BASE_URL,
          createRefreshTokenFormData(),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (resp.status === 200 && resp?.data?.isSuccess) {
          Cookies.set("isAlreadyFetchingAccessToken", "false", {
            secure: true,
            sameSite: 'Strict',
          });
          clearInterceptor();
          interceptors(resp?.data?.data?.accessToken, "application/json");
          Cookies.set("tdmAccessToken", resp?.data?.data?.accessToken, {
            secure: true,
            sameSite: 'Strict',
          });
          Cookies.set("tdmRefreshToken", resp?.data?.data?.refreshToken, {
            secure: true,
            sameSite: 'Strict',
          });
          onAccessTokenFetched(resp?.data?.data?.accessToken);
          return axios(originalRequest);
        }
        Cookies.set("isAlreadyFetchingAccessToken", "false", {
          secure: true,
          sameSite: 'Strict',
        });
        clearCache();
      } else if (originalRequest?.url === LOGIN_BASE_URL) {
        clearCache();
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

const post = (url = "", data = {}, config = {}) => {
  return axios.post(
    url,
    data,
    Object.assign(config, { timeout: 15 * 60 * 1000 })
  );
};

const get = (url = "", data = {}, config = {}) => {
  return axios(url, data, config);
};

const put = (url = "", data = {}, config = {}) => {
  return axios.put(url, data, config);
};

const del = (url = "", data = {}, config = {}) => {
  return axios.delete(url, { headers: config, data: data });
};

const HttpClient = {
  post,
  get,
  put,
  interceptors,
  del,
  clearInterceptor,
};

export { HttpClient };
