import React from "react";

const FirstMenuBlueicon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C18 12.2091 17.1046 14.2091 15.6569 15.6569L17.0711 17.0711C18.8807 15.2614 20 12.7614 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 12.7614 1.11929 15.2614 2.92893 17.0711L4.34315 15.6569C2.89543 14.2091 2 12.2091 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.293 5.29297L8.793 9.793L10.2072 11.2072L14.7072 6.70718L13.293 5.29297Z"
        fill="#150E44"
      />
    </svg>
  );
};

export default FirstMenuBlueicon;
