import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { adminRoutes } from "./routes/index";
import NoMatch from "./pages/noMatch";

const SignIn = lazy(() => import("./pages/signIn"));

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        {adminRoutes?.map((route) => {
          return (
            <Route key={route.id} path={route.path} element={route.component} />
          );
        })}
        <Route path="*" element={<NoMatch />} />
      </Route>

      <Route
        path="/signin"
        element={<PublicRoute component={SignIn} restricted={true} />}
      />
    </Routes>
  );
};

export default RoutesComponent;
