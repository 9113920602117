import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../components/molecules/appLayout";
import Cookies from "js-cookie";

const PrivateRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userCookie = Cookies.get("tdmAccessToken");
  useEffect(() => {
    if (location.pathname === "/") {
      window.location.href = "/signin";
    }
  }, [location]);

  useEffect(() => {
    // Call navigate inside useEffect
    if (!userCookie) {
      navigate("/signin", { replace: true });
    }
  }, [userCookie]); // Add userCookie to the dependency array

  return userCookie ? <AppLayout /> : null;
};

export default PrivateRoute;
