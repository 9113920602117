import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const SkeletonTable = ({ maxHeight, rows = 7 }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        height: maxHeight ? maxHeight : "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "20px 20px" }}>
        <Skeleton
          style={{
            width: "100%",
            height: "2.5rem", // Adjust height for header skeleton
            marginTop: "20px",
          }}
          variant="text"
        />
        {Array.from({ length: rows }).map((_, index) => (
          <Skeleton
            key={index}
            style={{ width: "100%", marginTop: "10px", padding: "0.5rem" }}
            variant="text"
          />
        ))}
      </Box>
    </Grid>
  );
};

SkeletonTable.propTypes = {
  maxHeight: PropTypes.string,
  rows: PropTypes.number,
};

export default SkeletonTable;
