import { createSlice } from "@reduxjs/toolkit";
import { LightThemeColors } from "../../constants/colors/lightThemeColors";

const initialState = {
  language: "en",
  theme: "light",
  colors: LightThemeColors,
  sideBarOpen: false,
  openMenus: [],
  sideBarMenu: [],
};

export const appSlice = createSlice({
  name: "appVariables",
  initialState,
  reducers: {
    setCurrentTheme(state, action) {
      state.theme = action.payload;
    },
    setSideBarState(state, action) {
      state.sideBarOpen = action.payload;
    },
    setOpenMenus(state, action) {
      state.openMenus = action.payload;
    },
    setSideBarMenu(state, action) {
      state.sideBarMenu = action.payload;
    },
  },
});

export const {
  setCurrentTheme,
  setSideBarState,
  setOpenMenus,
  setSideBarMenu,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
