import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapRulesTableData: [],
  masterTableData: [],
  configurationsTableData: [],
  currentConfigId: "",
  loginEmail: "",
  currentConfigTitle: "",
  currentConfigDescription: "",
  isDraft: null,
  isTargetConnectionEdited: false,
  currentConfigEnabled: false,
  isDataBasePopulated: false,
  mapRuleTableCurrentPage: 0,
  mapRuleTableCurrentPerPage: 10,
  openTableRuleModal: false,
  isMapRuleNeedToReload: false,
  mapRuleTableLoading: false,
  expandedRowsFromRedux: [],
  tableRulesList: [],
  viewRelationTableDetails: {},
  targetPasswordSave: "",
};
export const configurationSlice = createSlice({
  name: "configurationRelatedData",
  initialState,
  reducers: {
    setMapRuleTableData: (state, action) => {
      state.mapRulesTableData = action.payload;
    },

    setMasterTableData: (state, action) => {
      state.masterTableData = action.payload;
    },
    settablerulesList: (state, action) => {
      state.tableRulesList = action.payload;
    },

    setChangeInReduxForMasterTableChange: (state, action) => {
      const updateChildTablesRecursively = (childTables, changedItem) => {
        if (!childTables || !Array.isArray(childTables)) {
          return false; // No match found
        }

        for (const childTable of childTables) {
          if (childTable.databaseTableId === changedItem.databaseTableId) {
            childTable.masterTable = !childTable.masterTable;
            return true; // Match found, no need to check further
          }
          // Recursive call to update inner child tables
          if (
            updateChildTablesRecursively(childTable.childTables, changedItem)
          ) {
            return true; // Match found in recursion, stop further checks
          }
        }

        return false; // No match found
      };
      // Update masterTableData
      state.masterTableData.forEach((item) => {
        action.payload.forEach((changedItem) => {
          if (item.databaseTableId === changedItem.databaseTableId) {
            item.masterTable = !item.masterTable;
          }
        });
      });

      // Update mapRulesTableData and their child tables
      state.mapRulesTableData.forEach((item) => {
        action.payload.forEach((changedItem) => {
          if (item.id === changedItem.databaseTableId) {
            item.isMasterTable = !item.isMasterTable;
          } else if (item.childTables && Array.isArray(item.childTables)) {
            // Check and update child tables recursively
            if (updateChildTablesRecursively(item.childTables, changedItem)) {
              // If a match was found in the child tables, skip further checks for this item
              return;
            }
          }
        });
      });
    },

    setLoginEmail: (state, action) => {
      state.loginEmail = action.payload;
    },
    setCurrentConfigId: (state, action) => {
      state.currentConfigId = action.payload;
    },
    setIsDraft: (state, action) => {
      state.isDraft = action.payload;
    },
    setCurrentConfigTitle: (state, action) => {
      state.currentConfigTitle = action.payload;
    },
    setCurrentConfigDescription: (state, action) => {
      state.currentConfigDescription = action.payload;
    },
    setTargetConnectionEdited: (state, action) => {
      state.isTargetConnectionEdited = action.payload;
    },
    setCurrentConfigEnabled: (state, action) => {
      state.currentConfigEnabled = action.payload;
    },
    setDatabasePopulated: (state, action) => {
      state.isDataBasePopulated = action.payload;
    },
    setViewRelationTableDetails: (state, action) => {
      state.viewRelationTableDetails = action.payload;
    },
    setTargetPasswordSave: (state, action) => {
      state.targetPasswordSave = action.payload;
    },

    setViewRelationNewDatabaseConstraintId: (state, action) => {
      const { columnId, databaseConstraintId } = action.payload;

      const updateConstraintId = (table) => {
        for (const column of table.columns) {
          if (column.databaseColumnId === columnId) {
            column.databaseConstraintId = databaseConstraintId;
            return true;
          }
        }
        if (table.childTables) {
          for (const childTable of table.childTables) {
            if (updateConstraintId(childTable)) {
              return true;
            }
          }
        }
        return false;
      };

      updateConstraintId(state.viewRelationTableDetails);
    },

    setMapRuleTableCurrentPerPage: (state, action) => {
      state.mapRuleTableCurrentPerPage = action.payload;
    },
    setMapRuleTableCurrentPage: (state, action) => {
      state.mapRuleTableCurrentPage = action.payload;
    },
    setOpenTableRuleModal: (state) => {
      state.openTableRuleModal = !state.openTableRuleModal;
    },
    setIsMapRuleNeedToReload: (state, action) => {
      state.isMapRuleNeedToReload = action.payload;
    },
    setMapRuleTableLoading: (state, action) => {
      state.mapRuleTableLoading = action.payload;
    },

    setConfigIsInProgress: (state, action) => {
      const { configId, status } = action.payload;
      state.configurationsTableData.forEach((item) => {
        if (item.configurationId === configId) {
          item.inProgress = status;
        }
      });
    },
    setEnableDisable: (state, action) => {
      state.configurationsTableData.forEach((item) => {
        if (item.configurationId === action.payload) {
          item.enabled = !item.enabled;
        }
      });
    },
    setCurrentConfigIdClear: (state) => {
      state.currentConfigId = "";
      state.currentConfigTitle = "";
      state.currentConfigDescription = "";
      state.mapRulesTableData = [];
      state.isDraft = null;
      state.isDataBasePopulated = false;
      state.isTargetConnectionEdited = false;
      state.currentConfigEnabled = false;
      state.mapRuleTableCurrentPage = 0;
      state.mapRuleTableCurrentPerPage = 10;
      state.masterTableData = [];
      state.expandedRowsFromRedux = [];
    },
    setConfigurationTableData: (state, action) => {
      state.configurationsTableData = action.payload;
    },
    setExpandedRowsFromRedux: (state, action) => {
      state.expandedRowsFromRedux = state.expandedRowsFromRedux.includes(
        action.payload
      )
        ? state.expandedRowsFromRedux.filter((id) => id !== action.payload)
        : [...state.expandedRowsFromRedux, action.payload];
    },

    applyTableRule: (state, action) => {
      const rules = action.payload;

      rules.forEach(
        ({ id, newRule, ruleId, appliedRuleValue, orderByColumnId }) => {
          state.mapRulesTableData.forEach((item) => {
            if (item.id === id) {
              item.appliedRule = newRule;
              item.ruleId = ruleId;
              item.appliedRuleValue = appliedRuleValue;
              item.orderByColumnId = orderByColumnId;
            }
          });
        }
      );
    },

    applyColumnRule: (state, action) => {
      const {
        id,
        newRule,
        appliedTable,
        ruleId,
        ruleTypeId,
        substitutionCategoryId,
      } = action.payload;

      const applyRuleRecursive = (tables) => {
        tables.forEach((table) => {
          if (
            table.id === appliedTable ||
            table.databaseTableId === appliedTable
          ) {
            // If the parent table ID matches appliedTable, apply the rule to its columns
            table.columns.forEach((column) => {
              if (column.databaseColumnId === id) {
                if (!column.appliedRule) {
                  column.appliedRule = {}; // Ensure appliedRule exists
                }
                column.appliedRule.ruleName = newRule;
                column.appliedRule.ruleId = ruleId;
                column.appliedRule.ruleTypeId = ruleTypeId;
                column.appliedRule.substitutionCategoryId =
                  substitutionCategoryId;
              }
            });
          } else if (table.childTables && table.childTables.length > 0) {
            // If the parent table ID doesn't match, recursively search in child tables
            applyRuleRecursive(table.childTables);
          }
        });
      };

      applyRuleRecursive(state.mapRulesTableData);
    },
  },
});

export const {
  setMapRuleTableData,
  setConfigurationTableData,
  applyTableRule,
  applyColumnRule,
  setCurrentConfigId,
  setCurrentConfigIdClear,
  setLoginEmail,
  setCurrentConfigTitle,
  setIsDraft,
  setCurrentConfigDescription,
  setTargetConnectionEdited,
  setCurrentConfigEnabled,
  setDatabasePopulated,
  setMapRuleTableCurrentPage,
  setMapRuleTableCurrentPerPage,
  setMasterTableData,
  setChangeInReduxForMasterTableChange,
  setOpenTableRuleModal,
  setIsMapRuleNeedToReload,
  setMapRuleTableLoading,
  setExpandedRowsFromRedux,
  setEnableDisable,
  setConfigIsInProgress,
  settablerulesList,
  setViewRelationTableDetails,
  setViewRelationNewDatabaseConstraintId,
  setTargetPasswordSave,
} = configurationSlice.actions;

export const configurationReducer = configurationSlice.reducer;
