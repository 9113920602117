export const LightThemeColors = {
  primary: { main: "#172258", light: "#C6E5F8", dark: "#1A7AAA" },
  secondary: { main: "#6691E6", light: "#C3D7EC", dark: "#dedee3" },
  background: {
    primary: "#150E44",
    secondary: "#D1C4E9",
    paperBack: "#232539",
  },
  success: { main: "#2ECC71BF", light: "#D3EEB5", dark: "#357A38" },
  error: { main: "#e05a5a", light: "#E6685E", dark: "#AA2E25" },
  warning: { main: "#CC642EBF", light: "#FEBFA7", dark: "#CC7900" },
  info: { main: "#36C5F0", light: "#C3D7EC", dark: "#4E55A6" },
  text: {
    primary: "#0C0C0E",
    secondary: "#0C0C0E",
    light: "#EFF3F9",
    label: "#417166",
    redLabel: "#8D2A25",
    expired: "#DA7772",
    logo: "#D5D7DD",
    green: "#5DA291",
    orange: "#E1931E",
    greyLight: "#BEBEBE",
    disabledText: "#BEBEBE",
    lightGrey: "#DA7772",
  },
  chip: {
    blue: "#1F99E2",
    darkBlue: "#2996A9",
    lightGreen: "#27B72F",
    lightOrange: "#F8B212",
    orange: "#E8A41D",
    green: "#198038",
    red: "#D84A2C",
    yellow: "#F3CE15",
    greenShade: "#EFF6F4",
    other: "#EBB0B0",
    redShade: "#FAEBEB",
    lightGrey: "#DA7772",
  },
  blanckText: "#AAAAAA",
  drawerPaper: "#3B3B3B66",
  button: { main: "#2C6150", light: "#C3D7EC", dark: "#4E55A6" },
};
