import { createTheme } from "@mui/material";
import { DarkThemeColors } from "../constants/colors/darkThemeColors";

export const theme = (data) => {
  const { primary, secondary, button, text } = data?.colors;
  return createTheme({
    palette: {
      primary: primary || {
        light: DarkThemeColors.primary.light,
        main: DarkThemeColors.primary.main,
        dark: DarkThemeColors.primary.dark,
      },
      secondary: secondary || {
        light: DarkThemeColors.secondary.light,
        main: DarkThemeColors.secondary.main,
        dark: DarkThemeColors.secondary.dark,
      },
      button: button || {
        light: DarkThemeColors.button.light,
        main: DarkThemeColors.button.main,
        dark: DarkThemeColors.button.dark,
      },
      text: text || {
        primary: DarkThemeColors.text.primary,
        secondary: DarkThemeColors.text.secondary,
        light: DarkThemeColors.text.light,
        label: DarkThemeColors.text.label,
        redLabel: DarkThemeColors.text.redLabel,
        expired: DarkThemeColors.text.expired,
        logo: DarkThemeColors.text.logo,
        green: DarkThemeColors.text.green,
        orange: DarkThemeColors.text.orange,
        greyLight: DarkThemeColors.text.greyLight,
        disabledText: DarkThemeColors.text.disabledText,
        lightGrey: DarkThemeColors.text.lightGrey,
      },
    },
  });
};
