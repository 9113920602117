export const dateFormatting = (dateTochange) => {
  const date = new Date(dateTochange);

  const formattedDate = `${date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })} ${date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
  return formattedDate;
};
