import { enqueueSnackbar } from "../../molecules/notifier/notifier.actions";

export const callSnackbar = (dispatch, type, mess) => {
  return dispatch(
    enqueueSnackbar({
      message: mess,
      options: {
        variant: type,
      },
    })
  );
};
