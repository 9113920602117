import React from "react";

const SecondMenuIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.32943 0.271579C3.56252 -0.166801 4.9923 0.107489 5.97927 1.09446C7.1002 2.21537 7.3019 3.90741 6.5843 5.23384L17.293 15.9437L15.8788 17.358L5.16982 6.64875C3.84325 7.36686 2.15069 7.16536 1.02952 6.04421C0.0422707 5.05696 -0.231899 3.62665 0.207011 2.39332L2.44373 4.63C3.02952 5.21578 3.97927 5.21578 4.56505 4.63C5.15084 4.04421 5.15084 3.09446 4.56505 2.50868L2.32943 0.271579ZM12.6968 2.15512L15.8788 0.387349L17.293 1.80157L15.5252 4.98355L13.7574 5.3371L11.6361 7.45846L10.2219 6.04421L12.3432 3.92289L12.6968 2.15512ZM5.97927 10.2869L7.3935 11.7011L2.09018 17.0044C1.69966 17.3949 1.06649 17.3949 0.675971 17.0044C0.313341 16.6418 0.287441 16.0699 0.598261 15.6774L0.675971 15.5902L5.97927 10.2869Z"
        fill="white"
        fill-opacity="0.56"
      />
    </svg>
  );
};

export default SecondMenuIcon;
