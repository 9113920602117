import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoMatch = () => {
  const navigate = useNavigate()
  return (
    <Grid
    container
    direction={"column"}
    spacing={3}
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "22px",
        color: "grey",
      }}
    >
      <Grid item>
      404 | Not Found

      </Grid>
      <Grid item>
      <Button variant="outlined" onClick={()=> navigate("/dashboard", { replace: true })}>Back</Button>
        
        </Grid>
    
    </Grid>
  );
};

export default NoMatch;
