import React from "react";

const FailedIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16675 11.5H9.83342V13.1667H8.16675V11.5ZM8.16675 4.83335H9.83342V9.83335H8.16675V4.83335ZM8.99175 0.666687C4.39175 0.666687 0.666748 4.40002 0.666748 9.00002C0.666748 13.6 4.39175 17.3334 8.99175 17.3334C13.6001 17.3334 17.3334 13.6 17.3334 9.00002C17.3334 4.40002 13.6001 0.666687 8.99175 0.666687ZM9.00008 15.6667C5.31675 15.6667 2.33341 12.6834 2.33341 9.00002C2.33341 5.31669 5.31675 2.33335 9.00008 2.33335C12.6834 2.33335 15.6667 5.31669 15.6667 9.00002C15.6667 12.6834 12.6834 15.6667 9.00008 15.6667Z"
        fill="#C62828"
      />
    </svg>
  );
};

export default FailedIcon;
