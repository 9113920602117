import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchIconSvg from "../../svg/SearchIconSvg";

export default function SearchBox({ searchOpen, setSearchOpen }) {
  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 350 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search... "
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={() => setSearchOpen(false)}
      >
        {searchOpen ? <CloseIcon /> : <SearchIconSvg />}
      </IconButton>
    </Paper>
  );
}
