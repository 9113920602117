import { ApiService } from "../../services/api.services";

export function CallDahsboardData() {
  return () => {
    return ApiService.CallGetDahsboardData()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // throw err;
      });
  };
}
