import { Grid, Tooltip, Typography } from "@mui/material";
import DashboardCard from "../../components/atoms/DashboardCard";
import TitleSecction from "../../components/molecules/TitleSecction";
import PieChartComponent from "../../components/molecules/pieChart";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { CallDahsboardData } from "./dashboardAction";
import FailedIcon from "../../svg/FailedIcon";
import PendingIcon from "../../svg/PendingIcon";
import SucessIcon from "../../svg/SucessIcon";
import { dashboardStyles } from "./useStyles";
import { dateFormatting } from "../../components/molecules/dateFormatting";
import EnhancedTable from "../../components/molecules/EnhancedTable";

const headCells = [
  {
    id: "config",
    numeric: false,
    disablePadding: true,
    label: "Config. Name",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date & Time",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({});
  const [rows, setRows] = useState([]);
  const [gridItemHeight, setGridItemHeight] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(CallDahsboardData());
    const data = res?.data?.data;
    if (data) {
      setDashboardData(data);
      const rowItems = data.executionLogs.map((logs) =>
        createData(
          logs.configurationName,
          dateFormatting(logs.startTime),
          getDashboardIcons(logs.status)
        )
      );
      setRows(rowItems);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const createData = (config, date, status) => ({
    config,
    date,
    status,
  });

  const getDashboardIcons = (status) => {
    switch (status) {
      case "Completed":
        return (
          <Grid container spacing={1}>
            <Grid item>
              <SucessIcon />
            </Grid>
            <Grid item sx={{ color: "#2E7D32" }}>
              Success
            </Grid>
          </Grid>
        );
      case "In Progress":
        return (
          <Grid container spacing={1}>
            <Grid item>
              <PendingIcon />
            </Grid>
            <Grid item sx={{ color: "#F9A825" }}>
              In Progress
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container spacing={1}>
            <Grid item>
              <FailedIcon />
            </Grid>
            <Grid item sx={{ color: "#C62828" }}>
              Failed
            </Grid>
          </Grid>
        );
    }
  };

  useEffect(() => {
    const getGridItemHeight = () => {
      const gridItem = document.getElementById("gridItem");
      if (gridItem) {
        setGridItemHeight(gridItem.clientHeight);
      }
    };

    getGridItemHeight();
    window.addEventListener("resize", getGridItemHeight);

    return () => window.removeEventListener("resize", getGridItemHeight);
  }, []);

  const getPercentage = (count) => {
    const totalSuccessFailureRuns =
      dashboardData.successCount + dashboardData.failCount;
    return totalSuccessFailureRuns
      ? Math.round((count / totalSuccessFailureRuns) * 100)
      : "0%";
  };

  const chartData = [
    { name: "Success", value: getPercentage(dashboardData.successCount) || 0 },
    { name: "Failed", value: getPercentage(dashboardData.failCount) || 0 },
  ];

  const COLORS = ["#81C784", "#FECDD2"];

  const LegendData = [
    {
      color: "#FECDD2",
      label: "Failed",
      value: `${getPercentage(dashboardData.failCount)}%`,
    },
    {
      color: "#81C784",
      label: "Success",
      value: `${getPercentage(dashboardData.successCount)}%`,
    },
  ];

  return (
    <Grid
      sx={{
        padding: "1rem",
        backgroundColor: "#EFEFEF",
        minHeight: "100vh",
        marginTop: 0,
      }}
    >
      <TitleSecction mainTitle={"Dashboard"} />
      <Grid container justifyContent="center" flexDirection="row" spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container flexDirection="column" justifyContent="space-between">
            <Grid item sx={{ width: "100%" }}>
              <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                  onClick={() => navigate("/configurations", { replace: true })}
                >
                  <DashboardCard
                    title={dashboardData.totalConfiguration || 0}
                    subText={"Configurations"}
                    iconPath={"/assets/images/configurationCardIcon.png"}
                    iconBg={"#E1F5FE"}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  onClick={() => navigate("/execution-logs", { replace: true })}
                >
                  <DashboardCard
                    title={dashboardData.totalRuns || 0}
                    subText={"Runs"}
                    iconPath={"/assets/images/runCardIcon.png"}
                    iconBg={"#FFE0B2"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#FFFFFF",

                height: "calc(100vh - 250px)",
                marginTop: "1rem",
                borderRadius: "10px 10px 10px 10px",
              }}
            >
              <Grid container sx={{ height: "calc(100vh - 316px)" }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    background: "#D1C4E9",
                    paddingY: "1.3rem",
                    paddingLeft: "10px",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <Typography sx={dashboardStyles.leftRightCardTitle}>
                    Recent Executions
                  </Typography>
                </Grid>

                {!loading && rows?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No data found
                  </Grid>
                ) : (
                  <Grid
                    xs={12}
                    item
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <EnhancedTable
                      rows={rows}
                      headCells={headCells}
                      cellPadding={"0.7rem"}
                      headerColor={"white"}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6} sx={dashboardStyles.secondPart}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item sx={{ width: "100%" }}>
              <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={6}>
                  <DashboardCard
                    title={dashboardData.successCount || 0}
                    subText={"Success"}
                    iconPath={"/assets/images/successCardIcon.png"}
                    iconBg={"#C8E6C9"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DashboardCard
                    title={dashboardData.failCount || 0}
                    subText={"Failed"}
                    iconPath={"/assets/images/CardFailedIcon.png"}
                    iconBg={"#FECDD2"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                height: "calc(100vh - 250px)",
                marginTop: "1rem",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
              }}
            >
              <Grid container sx={{ height: `calc(100% - 40px)` }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    background: "#D1C4E9",
                    paddingY: "1.3rem",
                    paddingLeft: "10px",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <Typography sx={dashboardStyles.leftRightCardTitle}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ height: "100%" }}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        height: "65%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      xs={12}
                      id="gridItem"
                    >
                      {chartData[0].value || chartData[1].value ? (
                        <PieChartComponent
                          chartData={chartData}
                          COLORS={COLORS}
                          gridItemHeight={gridItemHeight}
                        />
                      ) : (
                        "No Executions"
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        sx={{
                          paddingLeft: "4rem",
                          paddingBottom: "1rem",
                        }}
                      >
                        {LegendData?.map((item, index) => (
                          <Grid
                            item
                            key={index + 1}
                            container
                            alignItems="center"
                            xs={4}
                          >
                            <Tooltip title={item?.value} placement="top" arrow>
                              <Grid
                                style={{
                                  width: 80,
                                  height: 35,
                                  backgroundColor: item?.color,
                                  marginRight: 8,
                                }}
                              ></Grid>
                            </Tooltip>

                            <Typography>{item.label}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
