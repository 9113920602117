import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload?.length) {
    return (
      <div className="custom-tooltip">
        <div>
          {payload?.map((pld) => (
            <div style={{ padding: 10 }}>
              <div
                style={{
                  color: "#D4CCFD",
                  background: "#150E44",
                  padding: "8px",
                  borderRadius: "5px",
                }}
              >
                {pld?.name} : {pld?.value}%
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default function PieChartComponent({
  chartData,
  COLORS,
  gridItemHeight,
}) {
  return (
    <div style={{ height: `calc(${gridItemHeight}px - 20px)`, width: "100%" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            outerRadius={`calc((${gridItemHeight}px - 10) / 2)`}
          >
            {chartData?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
