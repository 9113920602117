import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import MiniDrawer from "../sideBar/sideBar";

const AppLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <MiniDrawer />
      <Box component="content" sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
export default AppLayout;
