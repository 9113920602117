import FirstMenuIcon from "../../../svg/FirstMenuIcon";
import SecondMenuIcon from "../../../svg/SecondMenuIcon";
import ThirdMenuIcon from "../../../svg/ThirdMenuIcon";
import FourthMenuIcon from "../../../svg/FourthMenuIcon";
import FirstMenuBlueicon from "../../../svg/FirstMenuBlueicon";
import SecondMenuBlueIcon from "../../../svg/SecondMenuBlueIcon";
import ThirdMenuBlueIcon from "../../../svg/ThirdMenuBlueIcon";
import FourthMenuBlueIcon from "../../../svg/FourthMenuBlueIcon";

export const sidebarMenus = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: <FirstMenuIcon />,
    iconBlue: <FirstMenuBlueicon />,
    path: "/dashboard",
  },
  {
    id: "configurations",
    label: "Configurations",
    icon: <SecondMenuIcon />,
    iconBlue: <SecondMenuBlueIcon />,
    path: "/configurations",
  },
  {
    id: "execution-log",
    label: "Execution Log",
    icon: <ThirdMenuIcon />,
    iconBlue: <ThirdMenuBlueIcon />,
    path: "/execution-logs",
  },
  {
    id: "rules-library",
    label: "Rules Library",
    icon: <FourthMenuIcon />,
    iconBlue: <FourthMenuBlueIcon />,
    path: "/rules-library",
  },
];
