export const dashboardStyles = {
  leftRightCardTitle: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    color: "150E44",
  },

  secondPart:{
    "@media only screen and (max-width: 1200px)": {
      marginTop:"4.5rem"
    },
  }
};
