import { combineReducers } from "@reduxjs/toolkit";
import { appReducer } from "./appReducer/appReducer";
import { notifications } from "../components/molecules/notifier/notifierReducer";
import { configurationReducer } from "../pages/configurations/configurationSlice";
import { signInReducer } from "../pages/signIn/signInSlice";

const reducer = combineReducers({
  appReducer,
  notifications,
  configurationReducer,
  signInReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default rootReducer;
