import React from "react";

const PendingIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99992 2.33335H0.333252V0.666687H13.6666V2.33335H11.9999V4.00002C11.9999 5.34621 11.3198 6.42892 10.4633 7.31391C9.87758 7.91919 9.16492 8.47669 8.44234 9.00002C9.16492 9.52335 9.87758 10.0809 10.4633 10.6861C11.3198 11.5711 11.9999 12.6539 11.9999 14V15.6667H13.6666V17.3334H0.333252V15.6667H1.99992V14C1.99992 12.6539 2.68005 11.5711 3.5365 10.6861C4.12227 10.0809 4.83494 9.52335 5.5575 9.00002C4.83494 8.47669 4.12227 7.91919 3.5365 7.31391C2.68005 6.42892 1.99992 5.34621 1.99992 4.00002V2.33335ZM3.66659 2.33335V4.00002C3.66659 4.73716 4.02812 5.42529 4.73417 6.15488C5.33992 6.78085 6.12892 7.36652 6.99992 7.98177C7.87092 7.36652 8.65992 6.78085 9.26567 6.15488C9.97175 5.42529 10.3333 4.73716 10.3333 4.00002V2.33335H3.66659ZM6.99992 10.0183C6.12892 10.6335 5.33992 11.2192 4.73417 11.8452C4.02812 12.5748 3.66659 13.2629 3.66659 14V15.6667H10.3333V14C10.3333 13.2629 9.97175 12.5748 9.26567 11.8452C8.65992 11.2192 7.87092 10.6335 6.99992 10.0183Z"
        fill="#F9A825"
      />
    </svg>
  );
};

export default PendingIcon;
