import Backdrop from "@mui/material/Backdrop";
import loader from "../../../assets/images/loader.gif";

export default function AppLoader() {
  return (
    <Backdrop
      id="loaderContainer"
      sx={{ background: "white" }}
      open={true}
      onClick={() => null}
    >
      <img alt="logo" src={loader}></img>
    </Backdrop>
  );
}
