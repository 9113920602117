import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme as defaultTheme } from "./config/theme";
import { ThemeProvider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";
import AppLoader from "./components/atoms/appLoader/appLoader";
import CustomSnackbarProvider from "./components/atoms/snackbarProvider/snackbarProvider";
import "./utils/i18n";
import "./index.css";
import RoutesComponent from "./routesComponent";
import { history } from "./history";
import { authenticateApp } from "./services/api.services";
const App = () => {
  let { pathname, search } = useLocation();
  const [loader, setLoader] = useState(false);
  const appVariables = useSelector((store) => store?.appReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(authenticateApp())
      .then(() => {
        setLoader(false);
        history.push({
          pathname: pathname,
          search: search,
        });
      })
      .catch(() => {
        setLoader(false);
      });
    return () => {
      setLoader(false)
    }
  }, [navigate, pathname, search]);

  const newTheme = defaultTheme(appVariables);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={newTheme}>
        <CustomSnackbarProvider>
          {loader ? <AppLoader /> : <RoutesComponent />}
        </CustomSnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
