import loadable from "@loadable/component";
import Dashboard from "../pages/dashboard";

const ExecutionLog = loadable(() => import("../pages/executionLog"));
const RulesLibrary = loadable(() => import("../pages/rulesLibrary"));
const Configurations = loadable(() => import("../pages/configurations"));

const AddConfiguration = loadable(() =>
  import("../pages/configurations/AddConfiguration")
);

export const adminRoutes = [
  {
    id: "dashboard",
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    id: "configurations",
    path: "/configurations",
    component: <Configurations />,
  },
  {
    id: "executionlog",
    path: "/execution-logs",
    component: <ExecutionLog />,
  },
  {
    id: "ruleslibrary",
    path: "/rules-library",
    component: <RulesLibrary />,
  },
  {
    id: "addConfiguration",
    path: "/configurations/addConfiguration",
    component: <AddConfiguration />,
  },
];
