import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    fullName: "",
    email: "",
  },
};
export const signSlice = createSlice({
  name: "SignInUserData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData.fullName = action.payload.fullName;
      state.userData.email = action.payload.email;
    },
  },
});

export const { setUserData } = signSlice.actions;

export const signInReducer = signSlice.reducer;
