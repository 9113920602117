import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

const DashboardCard = ({ title, subText, iconPath, iconBg }) => {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: "1.5rem",
        width: "100%",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#150E44", // Change background color on hover
          color: "white",

          "& .subText": {
            color: "white",
          },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h4"
            sx={{
              fontFamily: "Noto sans",
              fontSize: "34px",
              fontWeight: "400",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body 2"
            className="subText"
            sx={{
              fontFamily: "Noto sans",
              fontSize: "14px",
              fontWeight: "400",
              color: "#00000099",
            }}
          >
            {subText}
          </Typography>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box
            sx={{
              background: iconBg,
              padding: "15px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt={title} src={iconPath} />
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default DashboardCard;
