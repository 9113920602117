import React from "react";

const ThirdMenuBlueIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59456 10.8115C5.53273 10.5511 5.5 10.2794 5.5 10C5.5 9.7207 5.53272 9.449 5.59454 9.1886L4.60291 8.6161L5.60291 6.88402L6.59523 7.45694C6.98834 7.08508 7.4676 6.80338 8 6.64494V5.5H10V6.64494C10.5324 6.80337 11.0116 7.08506 11.4047 7.4569L12.3971 6.88393L13.3972 8.616L12.4054 9.1885C12.4673 9.449 12.5 9.7207 12.5 10C12.5 10.2793 12.4673 10.551 12.4055 10.8114L13.3972 11.3839L12.3972 13.116L11.4048 12.543C11.0117 12.9149 10.5325 13.1966 10.0001 13.355V14.5H8.0001V13.3551C7.4677 13.1967 6.98844 12.915 6.59532 12.5431L5.60297 13.1161L4.60291 11.384L5.59456 10.8115ZM9 11.5C9.8284 11.5 10.5 10.8284 10.5 10C10.5 9.1716 9.8284 8.5 9 8.5C8.1716 8.5 7.5 9.1716 7.5 10C7.5 10.8284 8.1716 11.5 9 11.5ZM12 2H2V18H16V6H12V2ZM0 0.9918C0 0.44405 0.44749 0 0.9985 0H13L17.9997 5L18 18.9925C18 19.5489 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5447 0 19.0082V0.9918Z"
        fill="#150E44"
      />
    </svg>
  );
};

export default ThirdMenuBlueIcon;
