export const DarkThemeColors = {
  primary: { main: "#2C6150", light: "#C642EBF", dark: "#7AAA1A" },
  secondary: { main: "#6691E6", light: "#C3D7EC", dark: "#2462DB" },
  background: {
    default: "#17191C",
    paper: "#232529",
    light: "#232529",
    paperBack: "#FFFFFF",
  },
  success: { main: "#CC71BF", light: "#D3EEB5", dark: "#357A38" },
  error: { main: "#e05a5a", light: "#E6685E", dark: "#AA2E25" },
  warning: { main: "#CC642EBF", light: "#FEBFA7", dark: "#CC7900" },
  info: { main: "#36C5F0", light: "#C3D7EC", dark: "#4E55A6" },
  text: {
    primary: "#ffffff",
    secondary: "#FFFFFF",
    light: "#ffff",
    label: "#FFFFF",
    redLabel: "#FFFFF",
    expired: "#DA7772",
    logo: "#D5D7DD",
    green: "#5DA291",
    orange: "#E1931E",
    greyLight: "#BEBEBE",
    disabledText: "#474A53",
    lightGrey: "#DA7772",
  },
  chip: {
    blue: "#1F99E2",
    darkBlue: "#2996A9",
    lightGreen: "#27B72F",
    lightOrange: "#F8B212",
    orange: "#E8A41D",
    green: "#198038",
    red: "#D84A2C",
    yellow: "#F3CE15",
    greenShade: "#417166",
    other: "#EBB0B0",
    redShade: "#8D2A25",
    lightGrey: "#DA7772",
  },
  blanckText: "#AAAAAA",
  drawerPaper: "#3B3B3B66",
  button: { main: "#2C6150", light: "#C3D7EC", dark: "#4E55A6" },
};
