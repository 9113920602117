import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { sidebarMenus } from "./menus";
import { Grid, Tooltip } from "@mui/material";
import TDMLogo from "../../../svg/TDMLogo";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutDrawer from "../LogoutDrawer";
// import ExecutionStatusListener from "../../../ExectionStatusListener";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 21px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState("dashboard");
  const { pathname } = useLocation();

  React.useEffect(() => {
    var search = window.location.pathname.split("/")[1];

    setActiveTab(search);
  }, [pathname]);
  const [menuItems, setMenuItems] = React.useState(sidebarMenus);
  const handleMenuClick = (menuId, menuPath) => {
    const updatedMenus = sidebarMenus.map((menu) => ({
      ...menu,
      active: menu.id === menuId,
    }));
    setMenuItems(updatedMenus);
    navigate(menuPath, { replace: true });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent">
          <DrawerHeader
            sx={{
              background: "#150E44",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              color: "white",
              height: "16%",
              paddingTop: "2rem",
            }}
          >
            <TDMLogo />
          </DrawerHeader>
          <Grid
            container
            direction={"column"}
            alignItems={"space-between"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              background: "#150E44",
              height: "100%",
              minHeight: "500px",
              overflowY: "auto",
              paddingTop: "2rem",
            }}
          >
            <Grid item>
              <List>
                {menuItems.map((menu) => (
                  <ListItem key={menu.id} disablePadding>
                    <Tooltip title={menu.label} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          minHeight: 50,
                          maxWidth: 50,
                          justifyContent: "center",
                          borderRadius: "50%",
                          marginBottom: "2rem",
                          padding: "0.9rem",
                          backgroundColor:
                            activeTab === menu.id ||
                            (activeTab === "signin" && menu.id === "dashboard")
                              ? "#D1C4E9"
                              : "transparent",
                          "&:hover": {
                            backgroundColor:
                              activeTab !== "signin" || activeTab !== menu.id
                                ? "#322a65"
                                : "#D1C4E9",
                          },
                        }}
                        onClick={() => handleMenuClick(menu.id, menu.path)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: "center",
                          }}
                        >
                          {activeTab === menu.id ||
                          (activeTab === "signin" && menu.id === "dashboard")
                            ? menu.iconBlue
                            : menu.icon}
                        </ListItemIcon>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                paddingBottom: "2rem",
              }}
            >
              <LogoutDrawer />
            </Grid>
          </Grid>
        </Drawer>
      </Box>
    </>
  );
}
