import { SnackbarProvider } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import Notifier from "../../molecules/notifier";
import SucessComponent from "./SucessComponent";
import errorSnackbarComponent from "./errorSnackbarComponent";

const CustomSnackbarProvider = ({ children }) => {
  return (
    <SnackbarProvider
      hideIconVariant
      maxSnack={1}
      preventDuplicate={true}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      Components={{
        success: SucessComponent,
        error: errorSnackbarComponent,
      }}
    >
      <CssBaseline />
      <Notifier />
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
