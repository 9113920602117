import { useState, forwardRef, useCallback } from "react";
import clsx from "clsx";
import { successStyle } from "./successStyle";
import { useSnackbar, SnackbarContent } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid } from "@mui/material";

const SucessComponent = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} sx={successStyle.root}>
      <Card sx={successStyle.card}>
        <CardActions successStyle={{ root: successStyle.actionRoot }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="body2" sx={successStyle.typography}>
              {props.message}
            </Typography>
            <div sx={successStyle.icons}>
              <IconButton
                size="small"
                sx={successStyle.expand}
                onClick={handleDismiss}
                color="red"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

SucessComponent.displayName = "SucessComponent";

export default SucessComponent;
