import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import BackBtnIcon from "../../svg/BackBtnIcon";
import SearchBox from "../atoms/SearchBox";
import SearchIconSvg from "../../svg/SearchIconSvg";
import MenuList from "../atoms/MenuList";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const TitleSecction = ({
  mainTitle,
  subTitle,
  IsBackButton,
  hasSearch,
  hasDropDown,
  hasRedirectionButton,
  redirectionUrl,
  setSelectedStatus,
}) => {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingTop: "1rem",
      }}
    >
      <Grid item xs={7}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid item>
            <Typography
              varient="h5"
              sx={{
                fontFamily: "Noto sans",
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              {mainTitle}
            </Typography>
          </Grid>
          {subTitle || (
            <Grid item>
              <Typography>{subTitle}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {IsBackButton && (
          <Button variant="outlined" startIcon={<BackBtnIcon />}>
            Back
          </Button>
        )}

        {hasSearch && searchOpen && (
          <SearchBox searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        )}

        {hasSearch && !searchOpen && (
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <SearchIconSvg />
          </IconButton>
        )}
        {hasDropDown && <MenuList setSelectedStatus={setSelectedStatus} />}
        {hasRedirectionButton && (
          <Button
            variant="contained"
            endIcon={<AddIcon fontSize="large" />}
            sx={{ paddingX: "2.5rem", paddingY: "0.5rem" }}
            onClick={() => navigate(redirectionUrl, { replace: true })}
          >
            Add Configuration
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TitleSecction;
